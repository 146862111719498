import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const breakpointHeightPx = 700;

export const query = graphql`
  query {
    logoDesktop: file(relativePath: { eq: "images/onyx-falls-logo.png" }) {
      childImageSharp {
        fixed(width: 250, height: 250) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
    logoMobile: file(relativePath: { eq: "images/onyx-falls-logo.png" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const OnyxFallsLogo = () => {
  const data = useStaticQuery(query);

  const sources = [
    {
      ...data.logoDesktop.childImageSharp.fixed,
      media: `(min-height: ${breakpointHeightPx}px)`,
    },
    {
      ...data.logoMobile.childImageSharp.fixed,
      media: `(max-height: ${breakpointHeightPx}px)`,
    },
  ];

  return <Img alt="Onyx falls logo" fixed={sources} />;
};

export default OnyxFallsLogo;

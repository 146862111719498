import EtsyLogo from "./logos/EtsyLogo";
import InstagramLogo from "./logos/InstagramLogo";

/**
 * @typedef {{
 *  href: string,
 *  label: string,
 *  subheading: string,
 *  logo: JSX.Element,
 * }} LinkData
 *
 * @type {LinkData[]}
 */
export const links = [
  {
    href: "https://www.etsy.com/au/shop/OnyxFallsArt",
    label: "Etsy",
    logo: EtsyLogo,
  },
  {
    href: "https://www.instagram.com/onyx_falls/",
    label: "Instagram",
    logo: InstagramLogo,
  },
];

import React from "react";
import Head from "../components/Head";
import LinksList from "../components/LinksList";
import OnyxFallsLogo from "../components/OnyxFallsLogo";
import { links } from "../links";
import styles from "./styles.module.css";

const Home = () => (
  <>
    <Head />

    <div className={styles.gridContainer}>
      <div className={styles.logoRow}>
        <OnyxFallsLogo />
      </div>

      <div className={styles.linksRow}>
        <LinksList links={links} />
      </div>
    </div>
  </>
);

export default Home;

import React from "react";

const InstagramLogo = ({ className }) => (
  <svg
    className={className}
    style={{ enableBackground: "new 0 0 512 512" }}
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="comp_x5F_173-instagram">
      <g>
        <path d="M256.052,138.088c-65.268,0-117.913,52.646-117.913,117.912c0,65.267,52.645,117.91,117.913,117.91    c65.267,0,117.911-52.644,117.911-117.91C373.963,190.734,321.318,138.088,256.052,138.088z M256.052,332.659    c-42.177,0-76.659-34.38-76.659-76.659c0-42.279,34.378-76.657,76.659-76.657c42.277,0,76.657,34.378,76.657,76.657    C332.709,298.279,298.229,332.659,256.052,332.659L256.052,332.659z M406.288,133.266c0,15.291-12.315,27.502-27.502,27.502    c-15.292,0-27.502-12.314-27.502-27.502c0-15.188,12.313-27.503,27.502-27.503C393.973,105.763,406.288,118.077,406.288,133.266z     M484.382,161.177c-1.743-36.839-10.158-69.474-37.148-96.36c-26.886-26.887-59.52-35.302-96.36-37.148    c-37.971-2.155-151.777-2.155-189.747,0c-36.739,1.743-69.372,10.159-96.361,37.046c-26.99,26.887-35.302,59.52-37.149,96.36    c-2.155,37.969-2.155,151.777,0,189.747c1.745,36.842,10.159,69.473,37.149,96.361c26.989,26.887,59.521,35.301,96.361,37.148    c37.969,2.154,151.776,2.154,189.747,0c36.841-1.744,69.475-10.161,96.36-37.148c26.887-26.889,35.302-59.52,37.148-96.361    C486.538,312.853,486.538,199.147,484.382,161.177z M435.329,391.563c-8.004,20.112-23.5,35.61-43.716,43.716    c-30.273,12.009-102.108,9.236-135.562,9.236c-33.456,0-105.393,2.669-135.563-9.236c-20.114-8.005-35.611-23.498-43.717-43.716    C64.766,361.29,67.538,289.455,67.538,256s-2.669-105.391,9.234-135.562c8.006-20.114,23.5-35.61,43.717-43.716    c30.273-12.007,102.107-9.236,135.563-9.236c33.453,0,105.391-2.668,135.562,9.236c20.113,8.002,35.609,23.5,43.716,43.716    c12.007,30.272,9.237,102.107,9.237,135.562S447.336,361.392,435.329,391.563z" />
      </g>
    </g>
    <g id="Layer_1" />
  </svg>
);

export default InstagramLogo;

import React from "react";
import { Helmet } from "react-helmet";

/**
 * The page's `<head>` tag
 */
const Head = () => (
  <Helmet htmlAttributes={{ lang: "en" }}>
    <title>Onyx Falls</title>
    <meta charset="utf-8" />
    <meta name="referrer" content="origin" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <meta name="theme-color" content="#000000" />
  </Helmet>
);

export default Head;

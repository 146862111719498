import React from "react";
import styles from "./styles.module.css";

/**
 * @typedef {import("react").PropsWithChildren<HTMLAnchorElement> & {
 *  logo: () => JSX.Element,
 *  label: string,
 *  subheading?: string,
 * }} LinkProps
 *
 *
 * A link to an external site
 * @param {LinkProps} props
 */
const Link = ({ label, logo: Logo, subheading, ...restProps }) => (
  <a className={styles.link} {...restProps}>
    <Logo className={styles.logo} />

    <div className={styles.labelAndSubheading}>
      <span className={styles.label}>{label}</span>

      {subheading ? (
        <span className={styles.subheading}>{subheading}</span>
      ) : null}
    </div>
  </a>
);

export default Link;

import React from "react";

const EtsyLogo = ({ className }) => (
  <svg
    className={className}
    style={{ enableBackground: "new 0 0 512 512" }}
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g id="_x31_17-etsy">
      <g>
        <path d="M444.571,346.355c-1.719,10.56-13.505,108.037-15.224,129.644    c-115.773-4.222-215.968-4.657-361.919,0v-25.042c44.646-8.791,59.545-7.879,59.911-34.622c1.761-71.032,3.461-239.783,0-316.25    c-1.011-27.951-11.913-26.286-59.911-35.355V39.685c72.566,2.316,251.362,8.397,356.516-3.684    c-3.437,37.567-7.611,124.24-7.611,124.24h-22.832c-10.856-42.068-18.425-88.883-53.773-88.883H205.175    c-10.067,0-10.559,3.438-10.559,9.576V241.76c56.964,0.49,86.919-2.456,86.919-2.456c29.238-0.935,27.066-8.35,40.023-64.085    h25.289c-4.327,99.541-3.84,60.725-1.719,157.387H319.84c-8.992-39.369-8.904-59.953-38.797-60.4c0,0-21.115-1.965-86.428-1.965    v136.518c0,25.535,13.997,37.565,43.46,37.565h87.657c62.498,0,65.375-24.548,96.987-97.968H444.571L444.571,346.355z" />
      </g>
    </g>
    <g id="Layer_1" />
  </svg>
);

export default EtsyLogo;

import React from "react";
import Link from "../Link";
import styles from "./styles.module.css";

/**
 * @typedef {{ links: import("../../links").LinkData[] }} LinksListProps
 *
 *
 * A list of links to external sites
 * @param {LinksListProps} props
 */
const LinksList = ({ links }) => (
  <div className={styles.linksList}>
    {links.map((link) => (
      <Link
        key={link.label}
        label={link.label}
        subheading={link.subheading}
        logo={link.logo}
        href={link.href}
      />
    ))}
  </div>
);

export default LinksList;
